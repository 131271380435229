// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./variables.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loading {\r\n\r\n    margin: 0px;\r\n    height:  calc(100vh - 100px);\r\n \r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    background-color: var(--background-color);\r\n    transition: opacity 800ms ease-in;\r\n    opacity: 1;\r\n  }\r\n  \r\n.loading::before\r\n{\r\n  opacity: 0;\r\n}\r\n\r\n  \r\n  .loading__spinner {\r\n    width: 50px;\r\n    height: 50px;\r\n    border: 5px solid #ccc;\r\n    border-top-color: #333;\r\n    border-radius: 50%;\r\n    animation: spin 1s ease-in-out infinite;\r\n  }\r\n  \r\n  @keyframes spin {\r\n    from {\r\n      transform: rotate(0deg);\r\n    }\r\n    to {\r\n      transform: rotate(360deg);\r\n    }\r\n  }\r\n  ", "",{"version":3,"sources":["webpack://./src/pages/css/loading.css"],"names":[],"mappings":"AACA;;IAEI,WAAW;IACX,4BAA4B;;IAE5B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yCAAyC;IACzC,iCAAiC;IACjC,UAAU;EACZ;;AAEF;;EAEE,UAAU;AACZ;;;EAGE;IACE,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;IAClB,uCAAuC;EACzC;;EAEA;IACE;MACE,uBAAuB;IACzB;IACA;MACE,yBAAyB;IAC3B;EACF","sourcesContent":["@import './variables.module.css';\r\n.loading {\r\n\r\n    margin: 0px;\r\n    height:  calc(100vh - 100px);\r\n \r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    background-color: var(--background-color);\r\n    transition: opacity 800ms ease-in;\r\n    opacity: 1;\r\n  }\r\n  \r\n.loading::before\r\n{\r\n  opacity: 0;\r\n}\r\n\r\n  \r\n  .loading__spinner {\r\n    width: 50px;\r\n    height: 50px;\r\n    border: 5px solid #ccc;\r\n    border-top-color: #333;\r\n    border-radius: 50%;\r\n    animation: spin 1s ease-in-out infinite;\r\n  }\r\n  \r\n  @keyframes spin {\r\n    from {\r\n      transform: rotate(0deg);\r\n    }\r\n    to {\r\n      transform: rotate(360deg);\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
