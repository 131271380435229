// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./variables.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".about\r\n{\r\n    background-color: var(--second-background-color) !important;\r\n    border-color: transparent !important;\r\n    color: white !important;\r\n\r\n  \r\n}\r\n\r\n.about a\r\n{\r\n    text-decoration: none;\r\n}\r\n\r\n.about a:hover\r\n{\r\n    text-decoration: underline;\r\n}\r\n\r\n", "",{"version":3,"sources":["webpack://./src/pages/css/about.css"],"names":[],"mappings":"AACA;;IAEI,2DAA2D;IAC3D,oCAAoC;IACpC,uBAAuB;;;AAG3B;;AAEA;;IAEI,qBAAqB;AACzB;;AAEA;;IAEI,0BAA0B;AAC9B","sourcesContent":["@import './variables.module.css';\r\n.about\r\n{\r\n    background-color: var(--second-background-color) !important;\r\n    border-color: transparent !important;\r\n    color: white !important;\r\n\r\n  \r\n}\r\n\r\n.about a\r\n{\r\n    text-decoration: none;\r\n}\r\n\r\n.about a:hover\r\n{\r\n    text-decoration: underline;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
