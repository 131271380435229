// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../variables.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".user-detail-container\r\n{\r\n    color:white;\r\n    background-color:  var(--secondary-background-color);\r\n    padding: 20px;\r\n    margin: 20px 0;\r\n    border-radius: 8px;\r\n\r\n    display: flex;\r\n    align-items: center;\r\n    \r\n}\r\n\r\n.user-name\r\n{\r\n    font-size: 2.5em;\r\n    font-weight: 500;\r\n    margin: 0;\r\n}\r\n.user-image\r\n{\r\n    width: 100px;\r\n    height: 100px;\r\n    border-radius: 50%;\r\n    object-fit: cover;\r\n    box-shadow: 0 0 10px 5px  var(--secondary-background-color);\r\n}\r\n\r\n.user-image-container\r\n{\r\n    width: 100px;\r\n    height: 100px;\r\n    margin-right: 20px;\r\n\r\n\r\n}", "",{"version":3,"sources":["webpack://./src/pages/css/users/user-details.css"],"names":[],"mappings":"AAEA;;IAEI,WAAW;IACX,oDAAoD;IACpD,aAAa;IACb,cAAc;IACd,kBAAkB;;IAElB,aAAa;IACb,mBAAmB;;AAEvB;;AAEA;;IAEI,gBAAgB;IAChB,gBAAgB;IAChB,SAAS;AACb;AACA;;IAEI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,iBAAiB;IACjB,2DAA2D;AAC/D;;AAEA;;IAEI,YAAY;IACZ,aAAa;IACb,kBAAkB;;;AAGtB","sourcesContent":["@import '../variables.module.css';\r\n\r\n.user-detail-container\r\n{\r\n    color:white;\r\n    background-color:  var(--secondary-background-color);\r\n    padding: 20px;\r\n    margin: 20px 0;\r\n    border-radius: 8px;\r\n\r\n    display: flex;\r\n    align-items: center;\r\n    \r\n}\r\n\r\n.user-name\r\n{\r\n    font-size: 2.5em;\r\n    font-weight: 500;\r\n    margin: 0;\r\n}\r\n.user-image\r\n{\r\n    width: 100px;\r\n    height: 100px;\r\n    border-radius: 50%;\r\n    object-fit: cover;\r\n    box-shadow: 0 0 10px 5px  var(--secondary-background-color);\r\n}\r\n\r\n.user-image-container\r\n{\r\n    width: 100px;\r\n    height: 100px;\r\n    margin-right: 20px;\r\n\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
