// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../variables.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "tr{\r\n    color: white;\r\n}\r\n\r\nth:hover{\r\n    border-bottom: none !important;\r\n}\r\n\r\nth{\r\n    border-bottom: none !important;\r\n    cursor: default !important;\r\n}\r\n\r\n.backup-file-download\r\n{\r\n    cursor: pointer;\r\n}\r\n\r\ntd{\r\n    border-bottom: none !important;\r\n}\r\n\r\n.upload-file\r\n{\r\n    background-color:  var(--secondary-background-color) !important;\r\n    border-color:  var(--secondary-background-color) !important;\r\n    color: white !important;\r\n}\r\n\r\n.upload-file:focus\r\n{\r\n    box-shadow: none !important;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/pages/css/settings/backups.css"],"names":[],"mappings":"AACA;IACI,YAAY;AAChB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,8BAA8B;IAC9B,0BAA0B;AAC9B;;AAEA;;IAEI,eAAe;AACnB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;;IAEI,+DAA+D;IAC/D,2DAA2D;IAC3D,uBAAuB;AAC3B;;AAEA;;IAEI,2BAA2B;AAC/B","sourcesContent":["@import '../variables.module.css';\r\ntr{\r\n    color: white;\r\n}\r\n\r\nth:hover{\r\n    border-bottom: none !important;\r\n}\r\n\r\nth{\r\n    border-bottom: none !important;\r\n    cursor: default !important;\r\n}\r\n\r\n.backup-file-download\r\n{\r\n    cursor: pointer;\r\n}\r\n\r\ntd{\r\n    border-bottom: none !important;\r\n}\r\n\r\n.upload-file\r\n{\r\n    background-color:  var(--secondary-background-color) !important;\r\n    border-color:  var(--secondary-background-color) !important;\r\n    color: white !important;\r\n}\r\n\r\n.upload-file:focus\r\n{\r\n    box-shadow: none !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
